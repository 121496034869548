<template>
    <div class="kiosk-view">
        <PreviewComponent
            v-if="survey.kiosk"
            :expand="survey.kiosk.enabled"
            max-height="600px"
            title="Kiosk options"
            lead="Configure your kiosk based on your particular needs."
        >
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="kiosk-mode"
                            external-link="https://www.surveylegend.com/user-guide/create-kiosk-mode-survey-ipad-tablet/"
                        />
                        <span>Kiosk mode</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="survey.kiosk.enabled"
                        prevent
                        @change="handleChange($event, 'kiosk/enabled')"
                    />
                </RowComponent>
            </template>
            <template #options>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="reset-inactivity"
                            external-link="https://www.surveylegend.com/user-guide/create-kiosk-mode-survey-ipad-tablet/#inactive-time"
                        />
                        <span>Reset on inactivity</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="survey.kiosk.inactiveTimeEnabled"
                        prevent
                        short
                        @change="handleChange($event, 'kiosk/inactiveTimeEnabled')"
                    />
                </RowComponent>
                <transition name="slide-down">
                    <ColumnComponent v-if="survey.kiosk.inactiveTimeEnabled">
                        <ColumnComponent>
                            <RowComponent align="center">
                                <HelpComponent
                                    markdown="countdown-notice"
                                    external-link="https://www.surveylegend.com/user-guide/create-kiosk-mode-survey-ipad-tablet/#inactive-time"
                                />
                                <span>Display the countdown notice after...</span>
                            </RowComponent>
                            <StepperComponent
                                v-model="survey.kiosk.inactiveTime"
                                :min="3"
                                :max="3600"
                                @change="handleStepperChange($event, 'kiosk/inactiveTime')"
                            />
                        </ColumnComponent>
                        <ColumnComponent>
                            <RowComponent align="center">
                                <HelpComponent
                                    markdown="restart-timer"
                                    external-link="https://www.surveylegend.com/user-guide/create-kiosk-mode-survey-ipad-tablet/#inactive-restart-time"
                                />
                                <span>Then restart after...</span>
                            </RowComponent>
                            <StepperComponent
                                v-model="survey.kiosk.restartTime"
                                :min="5"
                                :max="3600"
                                @change="handleStepperChange($event, 'kiosk/restartTime')"
                            />
                        </ColumnComponent>
                        <template v-if="survey.thankYouPage.enabled">
                            <DividerComponent />
                            <ColumnComponent>
                                <RowComponent align="center">
                                    <HelpComponent
                                        markdown="thanks-timer"
                                        external-link="https://www.surveylegend.com/user-guide/create-kiosk-mode-survey-ipad-tablet/#inactive-restart-time"
                                    />
                                    <span>Keep thank you page visible for...</span>
                                </RowComponent>
                                <StepperComponent
                                    v-model="survey.kiosk.thankYouPageRestartTime"
                                    :min="5"
                                    :max="3600"
                                    @change="
                                        handleStepperChange($event, 'kiosk/thankYouPageRestartTime')
                                    "
                                />
                            </ColumnComponent>
                        </template>
                    </ColumnComponent>
                </transition>
            </template>
            <template #preview-toolbar>
                <transition name="slide-in">
                    <div v-if="survey.kiosk.inactiveTimeEnabled" class="kiosk-view__timer">
                        <i class="kiosk-view__timer__icon" :style="timerStyle" />
                        <span>{{ survey.kiosk.restartTime }} s</span>
                    </div>
                </transition>
            </template>
            <template #preview> </template>
        </PreviewComponent>
        <Teleport to="body">
            <UpgradeComponent ref="upgrade-kiosk-mode" name="kiosk-mode" image="kiosk-mode.png" />
        </Teleport>
    </div>
</template>

<script>
import { db } from '@/firebase'
import { mapGetters } from 'vuex'

const surveys = db.ref('v2/survey_meta/')

export default {
    name: 'Kiosk',

    metaInfo: {
        title: 'Kiosk'
    },

    data() {
        return {
            survey: {
                kiosk: {}
            }
        }
    },

    computed: {
        ...mapGetters({
            permissions: 'auth/permissions'
        }),

        timerStyle() {
            const style = {}

            style.animationDuration = `${this.survey.kiosk.restartTime}s`

            return style
        }
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                this.$rtdbBind('survey', surveys.child(id)).then((survey) => {
                    if (!survey.val().kiosk) {
                        this.handleSave({ kiosk: { enabled: false } })
                    }
                })
            }
        }
    },

    methods: {
        handleSave(data) {
            this.$firebaseRefs.survey.update(data)
        },

        async handleChange({ value }, toggle) {
            let permission
            let hasPermission

            const data = {}

            if (value) {
                switch (toggle) {
                    case 'kiosk/enabled':
                        permission = 'kiosk-mode'
                        hasPermission = this.permissions[permission]

                        break
                    case 'kiosk/inactiveTimeEnabled':
                        permission = 'kiosk-mode'
                        hasPermission = this.permissions[permission]

                        break
                    default:
                        hasPermission = true
                }

                if (!hasPermission) {
                    this.track('open-upgrade-modal', permission)

                    const isConfirmed = await this.$refs[`upgrade-${permission}`].open()

                    this.$refs[`upgrade-${permission}`].close()

                    if (isConfirmed)
                        window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`

                    return
                } else if (permission) {
                    this.track('has-permission', permission)
                }
            }

            data[toggle] = value

            this.handleSave(data)
        },

        handleStepperChange(value, stepper) {
            const data = {}

            data[stepper] = value

            this.handleSave(data)
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style lang="scss">
.kiosk-view {
    .preview-component__options {
        .stepper-component {
            width: auto;

            margin-top: 10px;
        }

        a {
            color: #337ab7;

            text-decoration: none;

            &:hover {
                color: #23527c;
            }
        }

        > .row-component {
            height: 32px;

            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        > .column-component .column-component {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .input-component__input {
            width: 100px;
        }

        .slide-down-enter-active,
        .slide-down-leave-active {
            transition: all 0.4s ease;
        }

        .slide-down-enter,
        .slide-down-leave-to {
            opacity: 0;
            transform: translateY(-10%);
        }
    }

    .preview-component__preview {
        .survey-background {
            top: 0;
        }

        .survey-container {
            height: auto;

            padding-top: 20px;
        }

        .slide-in-enter-active,
        .slide-in-leave-active {
            transition: all 0.4s ease;
        }

        .slide-in-enter,
        .slide-in-leave-to {
            opacity: 0;
            transform: translateX(100%);
        }
    }
}

.kiosk-view__timer {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    top: 40px;
    right: 40px;

    z-index: z-index('above');

    color: #34495e;

    font-weight: 500;

    padding: 12px 16px 12px 14px;

    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    pointer-events: none;

    animation: wiggle 4s ease infinite 3.5s;

    @keyframes wiggle {
        0%,
        25%,
        100% {
            transform: rotate3d(0, 0, 0, 0deg);
        }

        2%,
        4%,
        8%,
        12%,
        17% {
            transform: rotate3d(0, 0, 1, -3deg);
        }

        6%,
        10%,
        14%,
        20% {
            transform: rotate3d(0, 0, 1, 3deg);
        }
    }
}

.kiosk-view__timer__icon {
    @include icon('~@/assets/svg/icons/clock.svg');

    width: 16px;
    height: 16px;

    margin: -2px 12px 0 0;

    background-size: contain;

    animation: tick 60s infinite steps(60, start);

    @keyframes tick {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(90deg);
        }

        50% {
            transform: rotate(180deg);
        }

        75% {
            transform: rotate(270deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.kiosk-view__timer__inner {
    display: inline-block;
}
</style>
